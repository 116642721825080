import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "602px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2f193962f746890b66268b7ab83b7bc5/32056/loophero.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.35907335907336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADOElEQVQoz02T608aaRTGB7mYpqnrtriWakVABYWRy9zvwwyMwAwMIFocRQGhQalK68p2tbuurGtr0112TYOpadxke0napkn9tH/A/mMLaps+yXvO++WXc/K8zwvourqAcxlM3Qg0CXpBhKHo6TK5tEcUHuOFx1S+IagZL4Y6JjxQwGe8chX4oi69/uKyGQVLMYRMaMndd2rjk7rxRF3/NVE7VDf/ShSrd2R2Z1F4WSDuMTYA0LXVYS5ar7nv7L6UESF1eU3ZbDFJLRoP+5GAw+UUeNTh89Uj7pNFbHJi5L/fkm6XvQO2j9FoaFerw/7xuBafIkMRkZdCYWxibyFYEieHblnquVA15FpJ0817sWvm7/59VmBp+IIFeq/fMPf3ewLQaT2dS7EsRyIY/HRVEUnwcFX9s66pAvasKu/VsseP8vcVaFfj3QG433LTaDIBTi/kx0g3hPyT88cZ7/C4xw26j9bk6TD0vIj/vV9Ih/HDavyXDHJSFgjQHsBxkuchgrI6PUDPNz2WgQGPz9dcFjeyISXGxoL4HDnWyBLtJbKqtL8YJP3j1RSzrfHXR0CCZVlBsDnsl4YPWodhDNmaZZWIePeOVFFw29h4oyQX5zOzc9n9SnJmijbbxhnEe7QsyiEW57lRl0tv6JgFmPv6/Bh+VoYp79hKDH6Zg7bnqEoabZWpVk1diqKtEj8r4QfzTEEKtNZUlOVuW62Xk9uWgRD0+gd1bMS6JONH38+cHBRzWQrDwD+qiWY5XF8IP6xpv++WbHbb+w0BggOWwcEvSdE5nKNnzfJKknheoA5ybEVGf8pgPpx8mhd+nMa2kvgDFWkWOZtz9NPpls/v1esNn1Gd7tv+m2+WsQ0F/lnjGxnk9sTkfJKlGFyWGAzzI6h/PY4+WaCHBizvt2Tr8NBlLnVd58+tN3yohVkCrqaZF3c5Xo5G8uvSQiU4k1eWVlNador2nS5CO/M84hm5jFeHMnafs3rUdSvI4mEOi/NQNJ1KPTxWdt7EH72Nb7/SVh9wEUkS6SmRvtLT28lld7fBaAK+FkGhZFCAaZYKCnw0JqlJKT0rKAlaDFFBDmU4jCJMJsPFd9IbjP8DcOrXBotySW8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Loop Hero Title",
            "title": "Loop Hero Title",
            "src": "/static/2f193962f746890b66268b7ab83b7bc5/32056/loophero.png",
            "srcSet": ["/static/2f193962f746890b66268b7ab83b7bc5/a2ead/loophero.png 259w", "/static/2f193962f746890b66268b7ab83b7bc5/6b9fd/loophero.png 518w", "/static/2f193962f746890b66268b7ab83b7bc5/32056/loophero.png 602w"],
            "sizes": "(max-width: 602px) 100vw, 602px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Stop me if you heard this before: Loop Hero is a pixel graphic roguelike game. That's actually where most of the similarities stop though. Loop Hero is part deck builder, part Carcassone, part idle RPG. It's a strange combination that ends up working really well. As the game begins a lich has destroyed all of existence leaving our lone hero alone by campfire, with nothing to do but walk in a circle, slowly remembering bits and pieces of the world that was destroyed.`}</p>
    <h2>{`Gameplay`}</h2>
    <p>{`Gameplay is broken up into two main parts: expeditions and town building. `}</p>
    <h3>{`Town building`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b2f264e9d194516a369655aee392d1cf/9cea8/loophero-town.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABlElEQVQoz42R60vCYBTGt6kpSZmRpphppnlJzaZz0+kubrNpU2dQXsvK1jScaRT056eG9K6+9HA4HHh+z+HlvBC0ErzqOxbTmceeXNemybi0vr3lsJxYnlUno8HTvfIy0hl4wKnWUaWxqPRTNUX698wGBIJ0DM2VtLc3bT7vD4c6I3PkuJXQfpMY3hSuKhm5hLMnLuRP+GU2HU8nA0UfxgKO60VGwCUea9fyJa7YyMXExCEMMAxfUrXJ40gdPCu68LnPUa/QIpdncqjA5AUq05aKDSxoBBheLE8/3lVNG79OdWEytN+6yGbJAs2QNQ6/PPVI8QPUswsyfEV8/fwcz2bj+RwCr+2wmrmwi6cIsYhKCS8d3O81GZmIbMA/TLGQf7jrtrqdXr8HAcdcdt+OpcbiHIPLVLIcdotYBD92gxdPxKJcMlxNn8oUAYRX3bVlkYloncXaAua3b4L/vIIQLBZqZeKDiLcTD0C/tCCtRmTbBFuNMPDY9YQYqKBXObDdeWy3R07o//peQ6fiKpW9J9OPbO4LXhBRG3AGNPoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Town Building in Loop Hero",
            "title": "Town Building in Loop Hero",
            "src": "/static/b2f264e9d194516a369655aee392d1cf/e3189/loophero-town.png",
            "srcSet": ["/static/b2f264e9d194516a369655aee392d1cf/a2ead/loophero-town.png 259w", "/static/b2f264e9d194516a369655aee392d1cf/6b9fd/loophero-town.png 518w", "/static/b2f264e9d194516a369655aee392d1cf/e3189/loophero-town.png 1035w", "/static/b2f264e9d194516a369655aee392d1cf/9cea8/loophero-town.png 1278w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Town building is your typical roguelike hub affair where you can spend resources gathered during expeditions to build or upgrade buildings that allow more of the worlds populace to return and grant you buffs, new cards, new classes, etc. Much like the expeditions, you pick where to place the buildings slowly expanding the map, and at least in a few instances the placement really matters. A good example of this is the farm which will plant multiple gardens around it that will give you rations per loop you complete in an expedition. I haven't yet begun to plumb the depths of this system. I just unlocked the Supply Depot, which in addition to unlocking a new card for the deckbuilding aspect, allows me to store things I craft (so far at random) that give my Hero buffs while out and about in the world. I've unlocked about half the tech tree and have yet to upgrade any building I've constructed. `}</p>
    <h3>{`Expedition Preparation and Deckbuilding`}</h3>
    <p>{`When it's time to finally head out into the world and gather resources, you get to do two things. Pick your class and build your deck. There are three classes available in game right now: the warrior, the rogue, and the necromancer and they play very differently from one another. Each class has it's own set of gear that it can acquire and it's own set of stat bonuses it can acquire. Further augmenting this is a combination of buildings that allow you to acquire an additional gear slot while out on your expedition. You'll want to take your class into consideration when determining which cards to take with you on your expedition, as each class can function better with certain cards. Deckbuilding is a relatively simple affair. The cards you have unlocked are split into four slots and each slot can have a certain range of selected cards, while the deck size is also constrained to a certain number. Certain cards can only be added if others are, like the Blood Grove requires a Grove card to be available.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9d0ced92e4acc4694c5a8d7ab6ae2a02/3378d/loophero-deckbuilding.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.56756756756756%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAB2HAAAdhwGP5fFlAAAClUlEQVQoz22SaXOaUBSG+QVts7mhKAjIpkCiQeMuLjXGLe6KCIiooLHppDad/vnetM007XTmmTP3w3nmPefMhSxnu7HX9s5eO/Z25zj7nb1zDp8fn74en789H5+Ph8eDvXes/W4NeNhtHnaWY6+2lqJrUEVgewLdziSVtDhJi00x8lEkh1JslhFLDF5j8IrANFh8HEEAMyo4pYIKHZqFfaNqAcrz9DAt3t5W1Hq6IyeECIIgnuM0/zTOwH4PFfRQJHqD+kz0Qgud66FzUDXw9r2blDJQiSHWsvRp0d12y/ak2Yxz1Ti36ZbNZqGe4BoiVUuwnRiho24NeyXs0QKnYyDXrqJ9Fu1cRmQiIAU9LYFqXjJN2t+k4Fb6uiuJ7aw0uo4ZqOutvAicTMo5KBuje1K0lL1K8TRBovdFaVDL5kQiLxBVOXtXTDXa9XYmvgTCqzzH3L/lKhcx8vFVv96Ls9UYaRYSViXViYbbHGbK0qoYN8pJXeL+xP5MVv0nIzkHlUW2wpESz7WSQipKLDCPGjgFQy5R1wI5+03w/D8y2DnHUdpdcakPjcGtrraXVMDALgzMDdBRF+DlPG/N151HxTRUFxglwU3km2mS7yX5fgTphuFOGL7HYYUn1Cim/2O+TS7wzCAl1KslCUcwzxmD+rGgnw75WBTuNirjas742wRn03HvFP7QKWagIksactJSu5VE9JqnsxwucWSaxbI0utf721ZJC7lefgWY/xehCw1zqfD7AUgeom6F9C9oZE7ACuEbhH1T3DeM+Pu5q3ElPsxcKoR3jsNz3KvgXg33qqCS8DR41gOyqc0Mbb5a6isTYKi68X3fP1j3lrP68rQxt+aD2THmfUPXdGNhLRegB2As9amq/gDu/M6Q+uARtgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Deckbuilding",
            "title": "Deckbuilding",
            "src": "/static/9d0ced92e4acc4694c5a8d7ab6ae2a02/e3189/loophero-deckbuilding.png",
            "srcSet": ["/static/9d0ced92e4acc4694c5a8d7ab6ae2a02/a2ead/loophero-deckbuilding.png 259w", "/static/9d0ced92e4acc4694c5a8d7ab6ae2a02/6b9fd/loophero-deckbuilding.png 518w", "/static/9d0ced92e4acc4694c5a8d7ab6ae2a02/e3189/loophero-deckbuilding.png 1035w", "/static/9d0ced92e4acc4694c5a8d7ab6ae2a02/44d59/loophero-deckbuilding.png 1553w", "/static/9d0ced92e4acc4694c5a8d7ab6ae2a02/3378d/loophero-deckbuilding.png 2001w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Expeditions`}</h3>
    <p>{`Expeditions are the core of the game. They're the primary method of acquiring resources to upgrade your town, and only here can you summon the bosses to get through the games several acts. Each expedition starts out the same, you spawn into a nearly empty void on top of your campfire, with only a meandering, looping, path cutting through the void. As your hero forges ahead the time of day will advance, and as a new day dawns monsters can spawn or you can be healed or any of a number of effects based on how you built up the world. Initially only slimes will spawn along the path. If your hero wanders into one of these things, you'll be dropped into combat. Your hero will attack automatically until one side emerges victorious.`}</p>
    <p>{`Defeat in combat forces you back to camp with a mere 30% of the resources you managed to collect this run. Victory adds either new gear or new cards to your hand. Gear comes in different levels, rarities, and types (e.g. weapons, ring, shield, etc). Higher level gear tends to have higher base stats (e.g. more HP for the chest piece or more damage for the weapons). Higher rarity gear has more abilities on them, determined by your class. Gear can be switched out mid combat, or afterwards. A nice feature of combat is that while you are inspecting the gear in your inventory time will stop in combat. Slimes will drop relatively low level gear. In order to get better gear to drop you'll need to use the cards you get from combat to make stronger, higher level enemies to spawn.`}</p>
    <p>{`Each card in your hand has certain placement rules. Most enemy spawners, for example, need to placed on the path. Some, like the Spider Cocoon or Temporal Beacon, can be placed adjacent or near the path. What's important to take into account is how the cards interact with each other. Some of the combinations are fairly straightforward and explained like the Grove and the Blood Grove cards, the latter of which can only be placed adjacent to a Grove and adds tendrils that will auto kill any enemy who falls to 15% health within its range. Other combinations like placing nine rocks or mountains in a 3x3 grid are not explained anywhere in game and you'll probably bump into them on accident. In the rock case, a 3x3 grid turns in a Mountain Peak which gives you a ton of health, and also starts spawning Harpy's every two days. There are a lot of these combinations and none of them are explained, you'll just happen across them.`}</p>
    <p>{`You can't just start placing you cards willy nilly though, as you place cards around the map, you're slowly filling a gauge that when filled, will summon the acts boss. The first boss, the Lich, is on an entirely different level than the enemies you've been killing, so there's some tension around placing tiles to acquire better gear and summon the boss and placing them too fast and become overwhelmed by enemies on the loop.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5d041d682b620483fa85c5723a12b483/9cea8/loophero-expedition.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACXUlEQVQozxWR0VeacBzFfwKKaAooImaKCCIKgQgCYqnMMpFVOqXc0mXtVGtnnp2zpz3sjx875/tyX+79fu4F/nw09qzRsHc+cGbzmWnqI1vTurrturppKJrqnA93jw/zYOoHfri52+63Pw9v683998MBwDAcj8NoAoFiMQBAPI4gCJyIJAQiHYvFaKYk8VWlLaoCa2rt8dB8frj+vNtGLoDIUwSBp1NYMolmMkcJBKYLFIlnDFXp93Q6T6aSaHQYmqDyRB7PyKqyXN/sd+H+2yPAsGSzJUmSGFlYllktl6gcmcWznmNcOVrf0DS5NXadYp6Eo+cAkFX17T08/NxstktwlMK6hn6qKikMKxbpeq1mKPyFI3a10/8UKEokUc/UVFWmSKJUpBWlPQv88cy/ml2BFIa25RbPVfk6KzWqjtExTkWtyRQoCoIQJI5QeCbwBgLPsSdMxGOq0r0/9EaD6ac7IApcp6s3G4JlW5djN4dno8rQBBphcydkNp2URG7inRdyRJQZgyBd4ne2snU7q74JLNtuimK9Vq1zrNXteJZ24fZsQ+uqrY/nTbsj2b2u7fTYynGD5wsMPdTafyaDV7n2rgmAwHGczOFEFAhn0qm2JAhcNao3gcajnSAIiraM4I/SKcftW25/ORn9vZk+i+UXXQIMXZDrJblRqbFVga3wx4UyU6iWmQZfq7OVk1KRLRdlrlw5Lp4NLMPqBWf9X7b6yNEvagMsF7Pfe//H1yBczcPby+uJ/SUMwsX0fjXfhPPV4jK89Vb+cH37YRGcPb3uX9aLXYV84pmHGv0PHCpuLJHzDvQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Expedition",
            "title": "Expedition",
            "src": "/static/5d041d682b620483fa85c5723a12b483/e3189/loophero-expedition.png",
            "srcSet": ["/static/5d041d682b620483fa85c5723a12b483/a2ead/loophero-expedition.png 259w", "/static/5d041d682b620483fa85c5723a12b483/6b9fd/loophero-expedition.png 518w", "/static/5d041d682b620483fa85c5723a12b483/e3189/loophero-expedition.png 1035w", "/static/5d041d682b620483fa85c5723a12b483/9cea8/loophero-expedition.png 1278w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Overall Impressions`}</h2>
    <p>{`There is a surprising amount of depth to this game. Despite the length some runs can take, there is that all too sweet roguelike draw of "just one more run." If there's anything missing I'd say it's the ability to save deck/class combinations for quick re-use and some sort of indication of the card combinations you've discovered. All in all there's ton of re-playability and satisfying strategy. I strongly recommend it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      